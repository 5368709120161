$(function(){

  // タブ
  const tab     = '.tab';
  const label   = $(tab).find('.tab-label');
  const content = $(tab).find('.tab-content');
  const tag     = label.find('a');
  const current = 'current';
  //
  showTabContent();
  //
  tag.on('click', function(e){
    const self = $(this);
    e.preventDefault();
    label.find('li').removeClass(current);
    self.parent().addClass(current);
    showTabContent();
  });
  //
  function showTabContent(){
    const id = $( '.' + current ).find('a').attr('href');
    content.find('>*').hide();
    $(id).fadeIn();
  }
  // タブ

  // PageTop
  const pagetop = $('#pageTop');
  $(window).on('scroll', function () {
    if( $(this).scrollTop() > 100 ) {
      pagetop.fadeIn();
    } else {
      pagetop.fadeOut();
    }
  });
  //
  pagetop.on('click', function(){
    $('body, html').animate({ scrollTop : 0 }, 400);
    return false;
  });
  // PageTop

  // アコーディオン
  $('.accordion dt').on('click', function(){
    $(this).next('dd').slideToggle(180);
    $(this).toggleClass('open');
    $(this).next('dd').siblings('dd').slideUp(100);
    $(this).siblings('dt').removeClass('open');
  });
  // アコーディオン

  //smoothscroll
  $('a[href^="#"]').click(function(){
    //ヘッダーの高さ分だけずらす。
    var w= window.innerWidth;
    if( w < 760 ){
      var height = 48;
    }
    else if( w >= 760 && w < 980 ) {
      var height = 52;
    }
    else {
      var height = 0;
    }
    var speed = 400;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top-height;//←ここでずらす
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });

});
